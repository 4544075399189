import React from 'react'
import { observer } from 'mobx-react-lite'
import { Container, StyledNumberCell, WinBetContainer } from './styles'
import { NameFieldBets } from '../../services/nameFieldBets'
import { useStore } from '../../stores'
import { CellCoreMainField, SideCellsMainField, WinningShare } from '../index'
import { sideCells } from '../../constants/sideCells'

type NumberCellProps = {
  nameField: NameFieldBets
}

const CellMainField = observer((props: NumberCellProps): JSX.Element => {
  const { nameField } = props
  const { rouletteStore } = useStore()
  const { uiStore, lastBallsStore, statusGameStore, betsService } = rouletteStore
  const isShowWinShare: boolean = statusGameStore.isShowWinShare && `field:${lastBallsStore.lastBall}` === nameField
  const hoverCells = nameField
    .split(':')
    .slice(1)
    .map((item) => `field:${item}`) as Array<NameFieldBets>

  return (
    <Container>
      <StyledNumberCell
        disabled={!statusGameStore.isBetting}
        lightning={uiStore.mouseEnterTrackBets.includes(nameField) && statusGameStore.isBetting}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()

          betsService.addBet(nameField)
        }}
        onMouseEnter={(e) => {
          e.preventDefault()
          e.stopPropagation()

          uiStore.setMouseEnterTrackBets([...hoverCells, nameField])
        }}
        onMouseLeave={(e) => {
          e.preventDefault()
          uiStore.setMouseEnterTrackBets([])
        }}>
        {isShowWinShare && (
          <WinBetContainer>
            <WinningShare />
          </WinBetContainer>
        )}
        <CellCoreMainField nameField={nameField} />
      </StyledNumberCell>
      <SideCellsMainField
        left={sideCells[nameField]?.left}
        bottomLeft={sideCells[nameField]?.bottomLeft}
        bottom={sideCells[nameField]?.bottom}
      />
    </Container>
  )
})

export default CellMainField
