import 'webrtc-adapter'
import React, { FC, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { Global } from '@emotion/react'
import { io } from 'socket.io-client'
import { useEvent, useIdle, useMountedState } from 'react-use'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../stores'
import {
  LastBalls,
  Timer,
  Informer,
  FieldSwitcher,
  ActionBar,
  AddBalancePopup,
  Box,
  ExitGameBtn,
  WebrtcStream,
  VolumeBtn,
  PopupForm,
  VolumeRange,
  MainRouletteField,
} from '../../components'
import Container from './Container'
import routes from '../../core/routes'
import { gameById } from '../../api/game'
import {
  Fields,
  GlobalStyles,
  Main,
  Mid,
  PreTop,
  SubContainer,
  TopActionLeft,
  TopActionRight,
  TopActions,
  TopInfoContainer,
  TopInfoOfGame,
} from './styles'
import { allowedStatusGame } from '../../stores/ListGames.store'
import { rulSocketEvents } from '../../constants/rulSocketEvents'
import { ConnectedEventResponse } from '../../services/RouletteEvents'
import { sessionIdUnknownStatus } from '../../stores/Roulette/SessionId.store'
import QuickGameRoulette from '../../components/QuickGameRoulette'
import storage, { STORAGE_KEYS } from '../../services/storage'

const Roulette: FC = observer(() => {
  const isMounted = useMountedState()
  const isIdle = useIdle(process.env.REACT_APP_IDLE_OF_GAME as unknown as number)
  const { t } = useTranslation()
  const { rouletteGameId } = useParams()
  const navigate = useNavigate()
  const { rouletteStore, loginStore, notificationStore, listGamesStore, webrtcStreamStore, applicationStore } =
    useStore()
  const { uiStore, statusGameStore, userStore, standStore, sessionIdStore, depositStore } = rouletteStore
  const depositRef = useRef<number>(depositStore.deposit)

  const redirectToLobby = (): void => {
    if (isMounted()) navigate(routes.lobby, { replace: true })
  }

  const stopGameEvent = (): void => {
    notificationStore.showInfo(t('gameOverInfo'))

    setTimeout(() => {
      redirectToLobby()
    }, 5000)
  }

  const handleClickOpenAnotherGame = (newGameId: string): void => {
    if (rouletteGameId === newGameId) return

    navigate(`${routes.roulette}/${newGameId}`)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const init = (accessToken: string) => {
    const socket = io(`${process.env.REACT_APP_API_URL}/player`, {
      query: {
        game: rouletteGameId as string,
        role: 'player',
        accessToken,
        deposit: depositRef.current || storage.get(STORAGE_KEYS.ROULETTE_DEPOSIT) || 0,
      },
      secure: true,
      transports: ['websocket'],
      forceNew: true,
    })

    storage.remove(STORAGE_KEYS.ROULETTE_DEPOSIT)

    socket.on(rulSocketEvents.CONNECTED, (data: ConnectedEventResponse) => {
      rouletteStore.events.connectedEvent(data)

      rouletteStore.initGame({
        gameId: rouletteGameId as string,
        socket,
      })

      const games = listGamesStore.gameScheduleRoulette
        .filter((item) => item.id !== rouletteGameId)
        .map((item) => item.id)

      socket.emit(rulSocketEvents.SUBSCRIBE_GAMES, games)
    })

    socket.on(rulSocketEvents.END_BET, rouletteStore.events.endBetEvent)
    socket.on(rulSocketEvents.NEW_SESSION_OF_ROULETTE, rouletteStore.events.newSessionOfRouletteEvent)
    socket.on(rulSocketEvents.NO_SPIN, rouletteStore.events.noSpinEvent)
    socket.on(rulSocketEvents.PAUSE, rouletteStore.events.pauseEvent)
    socket.on(rulSocketEvents.RESULT, rouletteStore.events.resultEvent)
    socket.on(rulSocketEvents.ROULETTE_BET, rouletteStore.events.rouletteBetEvent)
    socket.on(rulSocketEvents.SELECT_NUMBER_ROULETTE, rouletteStore.events.selectNumberRouletteEvent)
    socket.on(rulSocketEvents.SESSION_END, rouletteStore.events.sessionEndEvent)
    socket.on(rulSocketEvents.SESSION_STATUS, rouletteStore.events.sessionStatusEvent)
    socket.on(rulSocketEvents.SPIN, rouletteStore.events.spinEvent)
    socket.on(rulSocketEvents.TIMER, rouletteStore.events.timerEvent)
    socket.on(rulSocketEvents.CHAT, rouletteStore.events.chatEvent)
    socket.on(rulSocketEvents.DEPOSIT, rouletteStore.events.depositEvent)
    socket.on(rulSocketEvents.SUBSCRIBE_GAMES, rouletteStore.events.subscribeGames)

    // выход из игры
    socket.on(rulSocketEvents.STOP_GAME, stopGameEvent)
    socket.on(rulSocketEvents.GAME_ERROR, redirectToLobby)
    socket.on(rulSocketEvents.CONNECT_ERROR, redirectToLobby)
    socket.on(rulSocketEvents.FINISH_GAME, redirectToLobby)
    socket.on(rulSocketEvents.DISCONNECT, redirectToLobby)

    return () => {
      socket.removeAllListeners()
      socket.disconnect()
    }
  }

  useEffect(() => {
    depositStore.setRatio(applicationStore.rouletteRatio)

    return () => {
      rouletteStore.resetGame()
    }
  }, [rouletteGameId])

  // ушел со страницы с игрой
  useEffect(() => {
    webrtcStreamStore.connectSocket()

    return () => {
      rouletteStore.resetGame()
    }
  }, [])

  // поменялась игра или токен
  useEffect(() => {
    const { accessToken } = loginStore

    const destroySocket = init(accessToken)

    return () => {
      destroySocket()
    }
  }, [loginStore.accessToken, rouletteGameId])

  useEffect(() => {
    depositRef.current = depositStore.deposit
  }, [depositStore.deposit])

  useEffect(() => {
    if (isIdle) redirectToLobby()
  }, [isIdle])

  const beforeUnloadEvent = (): void => {
    storage.set(STORAGE_KEYS.ROULETTE_DEPOSIT, depositRef.current)

    rouletteStore.sendForceDisconnect()
    rouletteStore.socket?.removeAllListeners()
  }

  useEvent('beforeunload', beforeUnloadEvent)

  const existGame = async (): Promise<void> => {
    if (document.visibilityState === 'visible' || !document.hidden) {
      const gameInfo = await gameById(rouletteGameId as string)

      if (allowedStatusGame.includes(gameInfo.gameSchedule.status)) return

      redirectToLobby()
    }
  }

  useEvent('visibilitychange', existGame)

  return (
    <>
      <Global styles={GlobalStyles} />
      {uiStore.isShowBalancePopup && <AddBalancePopup />}
      <PopupForm
        title={t('volumeSetting')}
        open={uiStore.isShowVolumePopup}
        showSubmit={false}
        showCancel={false}
        onClose={() => {
          uiStore.setIsShowVolumePopup(false)
        }}>
        <Box mb={15}>
          <VolumeRange />
        </Box>
      </PopupForm>
      <Container
        sessionId={sessionIdStore.id}
        userId={loginStore.user?.id}
        login={loginStore.user?.login}
        gameId={rouletteGameId as string}>
        <Main showMobileLayer={statusGameStore.isBetting}>
          <PreTop>
            <TopActions>
              <TopActionLeft>
                <QuickGameRoulette
                  currentGameId={rouletteGameId as string}
                  onClickOpenGame={handleClickOpenAnotherGame}
                />
              </TopActionLeft>

              <TopActionRight>
                <Box mr="5px">
                  <VolumeBtn />
                </Box>
                <ExitGameBtn />
              </TopActionRight>
            </TopActions>
          </PreTop>

          <div>
            <Timer />
            <TopInfoContainer>
              <Box display="flex">
                {standStore.name && <TopInfoOfGame>{standStore.name}</TopInfoOfGame>}
                {sessionIdStore.id !== sessionIdUnknownStatus && (
                  <TopInfoOfGame>, id {sessionIdStore.id}</TopInfoOfGame>
                )}
              </Box>
              <Informer />
              {userStore.name && <TopInfoOfGame>{userStore.name}</TopInfoOfGame>}
            </TopInfoContainer>
          </div>

          <SubContainer>
            <QuickGameRoulette currentGameId={rouletteGameId as string} onClickOpenGame={handleClickOpenAnotherGame} />
            <FieldSwitcher />
          </SubContainer>

          <Mid>
            <WebrtcStream />
            <Fields disabled={!statusGameStore.isBetting} isTranslucency={statusGameStore.isSpinStatus}>
              <MainRouletteField />
            </Fields>
          </Mid>

          <ActionBar />
        </Main>

        <LastBalls />
      </Container>
    </>
  )
})

export default Roulette
