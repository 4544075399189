import { FC, useEffect, useRef, useState } from 'react'
import { useClickAway } from 'react-use'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'
import { useStore } from '../../stores'
import closeIcon from './img/close.svg'
import { Box, Button, Currency, CurrencyDiamond, TextField } from '../index'
import { numberWithSpaces } from '../../utils/number'

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
`

const Popup = styled.form`
  display: block;
  background: #0b1b3a;
  box-shadow: 0 40px 20px -10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 20px 60px;
  position: fixed;
`

const Close = styled.div`
  position: absolute;
  top: 12px;
  right: 15px;
  cursor: pointer;
`

const Container = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
`

const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px;
`

const Balance = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 8px;
`

const Tooltip = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #98abd0;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const PseudoLink = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffc13a;
  cursor: pointer;
`

const Fields = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 16px;
  margin-bottom: 0;
  align-content: center;
`

const Field = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 8px;
  align-items: center;
  margin-bottom: 16px;

  input {
    text-align: right;
  }

  #moneyTextField {
    background: transparent;
  }
`

const AddBalancePopup: FC = observer(() => {
  const { applicationStore, rouletteStore } = useStore()
  const { balance, rouletteRatio } = applicationStore
  const ref = useRef(null)
  const { t } = useTranslation()
  const [money, setMoney] = useState<string>('')
  const [diamond, setDiamond] = useState<string>('')
  const { uiStore } = rouletteStore

  useClickAway(ref, () => {
    uiStore.setShowBalancePopup(false)
  })

  useEffect(() => {
    setMoney(diamond ? `${String(Number(diamond) * rouletteRatio)}` : '')
  }, [diamond])

  return (
    <Wrapper>
      <Popup
        ref={ref}
        onSubmit={(e) => {
          e.preventDefault()
          uiStore.setShowBalancePopup(false)
          rouletteStore.sendDeposit({
            deposit: Number(diamond),
          })
        }}>
        <Container>
          <Close
            onClick={(e) => {
              e.preventDefault()
              uiStore.setShowBalancePopup(false)
            }}>
            <img src={closeIcon} alt="close" />
          </Close>
          <Title>{t('balanceReplenishment')}</Title>
          <Balance>
            <div>
              {Math.floor(balance / rouletteRatio)}&nbsp;
              <CurrencyDiamond />
            </div>
            <div>~</div>
            <div>
              {numberWithSpaces(balance)} <Currency />
            </div>
          </Balance>
          <Box mb={12}>
            <Fields>
              <Field>
                <TextField
                  autoFocus
                  inputMode="numeric"
                  value={diamond}
                  id="diamondTextField"
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/

                    const newValue = e.target.value
                    // Проверка на пустое значение или положительное число
                    if (
                      newValue === '' ||
                      (Number(newValue) > 0 && !Number.isNaN(Number(newValue))) ||
                      re.test(newValue)
                    ) {
                      if (!newValue) {
                        setDiamond('')
                      } else {
                        const val = Number(newValue)
                        const maxVal = Math.floor(balance / rouletteRatio)

                        setDiamond(`${Math.min(val, maxVal)}`)
                      }
                    }
                  }}
                />
                <CurrencyDiamond />
              </Field>
              <Field className="money">
                <TextField value={money} disabled id="moneyTextField" />
                <Currency />
              </Field>
            </Fields>

            <Tooltip>
              {t('exchangeWillOccurAtTheRate')} {rouletteRatio}&nbsp;
              <Currency />
            </Tooltip>
          </Box>

          <Box mb={20}>
            <Button disabled={Number(money) > balance} label={t('continue')} type="submit" fullWidth />
          </Box>
          <PseudoLink
            onClick={(e) => {
              e.preventDefault()
              uiStore.setShowBalancePopup(false)
            }}>
            {t('cancel')}
          </PseudoLink>
        </Container>
      </Popup>
    </Wrapper>
  )
})

export default AddBalancePopup
