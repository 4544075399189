import { get, isEmpty, isNull, map, reduce } from 'lodash'
import { RouletteStore } from '../../stores/Roulette'
import { NameFieldBets } from '../nameFieldBets'
import { RUL_GAME_STATUS } from '../../stores/Roulette/StatusGame.store'
import { Bets, HistoryBets, TYPE_BET_ACTION } from '../../stores/Roulette/Bets.store'
import { WarningCode } from '../../stores/Roulette/Notification.store'

export type SessionStatusEventResponse = {
  status: RUL_GAME_STATUS // 'spin'
  timer: number // 0
  deposit: number // 0
  totalBets: number // 0
  listBets: HistoryBets
  actions: { repeat: boolean }
  sessionId: number
}

export type ConnectedEventResponse = {
  name: string // 'pipiska1'
  role: string // 'player'
  id: string // '5fb6-80d9-3544-8307'
  userId: string // '5fb6-80d9-3544-8307'
  room: string // '5fe6-0a5c-3b75-a107'
  status: number // 200
  sessionStatus: SessionStatusEventResponse
  dataOfGame: {
    stand: {
      status: number // 200
      name: string // 'for rul'
      typeGame: string // 'roulette'
      data: {
        name: string // 'UBIS'
        streamId: string // '484282862950950045466824'
      }
      grid: null
    }
    limits: {
      status: number // 200
      name: string // 'for rul'
      minimum: number // 10
      maximum: number // 10000
      maximumPayout: null
      nameGame: string // 'roulette'
      minBetColDozen: number // 5
      maxBetColDozen: number // 50000
      minBetEvenChance: number // 5
      maxBetEvenChance: number // 50000
    }
    lastBalls: Array<number | 'no spin' | null>
  }
}

export type NewSessionOfRouletteEventResponse = {
  message: string // ""
  sessionId: number // 4947
  status: number // 200
  timer: number // 25
  typeGame: string // "timer"
}

export type TimerEventResponse = {
  status: number
  remainingTime: number
}

export type SelectNumberRouletteEventResponse = {
  ball: number
  message: string
  status: number
}

export type SpinEventResponse = {
  status: number
}

export type NoSpinEventResponse = {
  message: string
  status: number
}

export type DepositEventResponse = {
  status: number
  deposit: number
  totalBets: number
}

export type ResultLoseBets = Partial<{
  [key in NameFieldBets]: {
    bet: number
  }
}>

export type ResultWinBets = Partial<{
  [key in NameFieldBets]: {
    bet: number
    win?: number
  }
}>

export type ResultEventResponse = {
  amountRelativeGame: number
  amountRelativeNumber: number
  deposit: number
  bets: {
    win: ResultWinBets
    lose: ResultLoseBets
  }
}

export type SessionEndEventResponse = {
  deposit: number
  message: string
  status: number
}

export type RouletteBetEventResponse = {
  bet: {
    yes: Bets
    not: Bets
  }
  deposit: number
  totalBets: number
  id: number
  isSpin: boolean
  listBets: HistoryBets
  message: string
  status: number
  type: TYPE_BET_ACTION
}

export type StopGameEventResponse = {
  status: number
}

export type SubscribeGamesEventResponse = {
  id: string
  status: string
  event: string
  totalBets: number
  ball: number
  result?: {
    amountRelativeGame: number
    amountRelativeNumber: number
    deposit: number
  }
}

export default class RouletteEvents {
  store: RouletteStore

  constructor(p: RouletteStore) {
    this.store = p
  }

  connectedEvent = (data: ConnectedEventResponse): void => {
    const { dataOfGame, sessionStatus } = data

    this.sessionStatusEvent(sessionStatus)

    this.store.userStore.setName(data.name)

    this.store.standStore.setName(data.dataOfGame.stand.name)

    this.store.limitsStore.setLimits(dataOfGame.limits)

    this.store.lastBallsStore.setLastBalls(data.dataOfGame.lastBalls.filter((i) => !isNull(i)))

    this.store.streamDataStore.setStreamData(data.dataOfGame.stand.data)
  }

  endBetEvent = (): void => {
    this.store.statusGameStore.setStatusGame(RUL_GAME_STATUS.END_BET)
  }

  newSessionOfRouletteEvent = (data: NewSessionOfRouletteEventResponse): void => {
    this.store.statusGameStore.setStatusGame(RUL_GAME_STATUS.PREPARING)

    this.store.sessionIdStore.setId(data.sessionId)

    this.store.timerStore.setTimer(get(data, 'timer', 0))

    // по умолчанию будем считать, что ставки были
    this.store.betsStore.setExistsBetsPrevSession(true)

    // вычистить предыдущие ставки с историей
    this.store.betsStore.setBets({})
    this.store.betsStore.setHistoryBets({})

    // сбросить
    this.store.lastVerifyDataStore.setLastVerifyData(null)

    this.store.payStore.setPay(0)

    // поставить выигрышные ставки
    this.store.betsService.addWinBets()

    // очистить проигрышные ставки
    this.store.betsStore.setLoseBets([])
  }

  noSpinEvent = (): void => {
    this.store.statusGameStore.setStatusGame(RUL_GAME_STATUS.NO_SPIN)
    this.store.notificationStore.setWarningCode(WarningCode.noSpin)
  }

  pauseEvent = (): void => {
    this.store.statusGameStore.setStatusGame(RUL_GAME_STATUS.PAUSE)
  }

  resultEvent = (data: ResultEventResponse): void => {
    const { deposit, bets } = data
    this.store.statusGameStore.setStatusGame(RUL_GAME_STATUS.RESULT)

    this.store.depositStore.setDeposit(deposit)

    const loseBets = map(bets.lose, (_, field) => field) as Array<NameFieldBets>

    this.store.betsStore.setLoseBets(loseBets)

    if (!data.amountRelativeNumber) return

    const winBets = reduce(
      data.bets.win,
      (acc, item, key) => ({
        ...acc,
        [key]: get(item, 'bet', 0),
      }),
      {}
    )

    this.store.betsStore.setWinBets(winBets)

    this.store.payStore.setPay(data.amountRelativeNumber)
  }

  rouletteBetEvent = (data: RouletteBetEventResponse): void => {
    const { listBets, totalBets, deposit } = data

    this.store.lastVerifyDataStore.setRouletteBet(data)

    this.store.lastVerifyDataStore.setLastVerifyData({
      listBets,
      deposit,
      totalBets,
    })

    if (data.type === TYPE_BET_ACTION.REPEAT) {
      if (!isEmpty(data.bet.yes)) {
        this.store.betsStore.mergeNewBets(data.bet.yes)
        this.store.betsStore.addHistoryBets(data.bet.yes, data.id)
        this.store.depositStore.decreaseDeposit(reduce(data.bet.yes, (acc, item) => acc + (item || 0), 0))
      }
    }
  }

  selectNumberRouletteEvent = (data: SelectNumberRouletteEventResponse): void => {
    this.store.statusGameStore.setStatusGame(RUL_GAME_STATUS.BAll)

    this.store.lastBallsStore.addLastBalls(data.ball)
    this.store.lastBallsStore.setLastBall(data.ball)
  }

  sessionEndEvent = (data: SessionEndEventResponse): void => {
    this.store.statusGameStore.setStatusGame(RUL_GAME_STATUS.END)
    this.store.depositStore.setDeposit(data.deposit)
  }

  sessionStatusEvent = (data: SessionStatusEventResponse): void => {
    const { status, listBets, deposit, totalBets, sessionId } = data

    this.store.betsStore.setExistsBetsPrevSession(data.actions.repeat)

    this.store.statusGameStore.setStatusGame(status)

    this.store.depositStore.setDeposit(deposit)

    this.store.betsStore.recoveryBets(listBets)

    this.store.sessionIdStore.setId(sessionId)

    this.store.lastVerifyDataStore.setLastVerifyData({
      listBets,
      deposit,
      totalBets,
    })
  }

  spinEvent = (): void => {
    this.store.statusGameStore.setStatusGame(RUL_GAME_STATUS.SPIN)

    this.store.timerStore.setTimer(0)

    if (isNull(this.store.lastVerifyDataStore.lastVerifyData)) return

    const { listBets, deposit } = this.store.lastVerifyDataStore.lastVerifyData

    const isSyncBets = this.store.betsStore.isSyncBets(listBets, this.store.betsStore.historyBets)

    if (isSyncBets) return

    this.store.betsStore.recoveryBets(listBets)
    this.store.depositStore.setDeposit(deposit)
  }

  timerEvent = (data: TimerEventResponse): void => {
    this.store.timerStore.setTimer(data.remainingTime)
  }

  chatEvent = (): void => {}

  depositEvent = (data: DepositEventResponse): void => {
    this.store.depositStore.setDeposit(data.deposit)
  }

  subscribeGames = (data: Array<SubscribeGamesEventResponse>): void => {
    this.store.subscribeGamesStore.setGames(data)
  }
}
