import { makeAutoObservable } from 'mobx'

class DepositStore {
  deposit: number = 0

  ratio: number = 1

  constructor() {
    makeAutoObservable(this)
  }

  setRatio = (ratio: number): number => {
    this.ratio = ratio

    return this.ratio
  }

  setDeposit = (deposit: number): number => {
    this.deposit = deposit

    return this.deposit
  }

  decreaseDeposit = (num: number): number => {
    this.deposit -= num

    return this.deposit
  }

  increaseDeposit = (num: number): number => {
    this.deposit += num

    return this.deposit
  }
}

export default DepositStore
