import http from '../../services/http'

// const response = {
//   gameSchedule: {
//     id: '6014-9e61-d0af-1307',
//     status: 250,
//     comments: null,
//     gameSettings: {
//       timer: { toSpin: 30, techPause: 5, toEndBets: 25 },
//       places: 1,
//       limitId: '5987-a7da-418a-9300',
//       standId: '5bf3-5b29-e3c8-4107',
//       game_name: 'roulette',
//       game_type: 'public',
//       ipCyclops: '',
//       listBonus: [],
//       betMaximum: 1,
//       typeMaximum: 'betMaximum',
//     },
//     startsAt: '2022-04-22T18:29:00.000Z',
//     endsAt: null,
//     acceptedDealer: '5fe6-0a40-401c-6b07',
//     createdBy: { operator_id: '1234-4321-0000-0000' },
//     createdFor: [],
//     createdAt: '2022-04-22T18:12:17.858Z',
//     updatedAt: '2022-04-22T18:14:40.489Z',
//   },
//   limits: {
//     id: '5987-a7da-418a-9300',
//     status: 200,
//     name: 'for rul',
//     minimum: 10,
//     maximum: 10000,
//     maximumPayout: null,
//     nameGame: 'roulette',
//     minBetColDozen: 5,
//     maxBetColDozen: 50000,
//     minBetEvenChance: 5,
//     maxBetEvenChance: 50000,
//     createdAt: '2018-09-28T05:20:55.557Z',
//     updatedAt: '2018-09-28T05:20:55.557Z',
//   },
//   stand: {
//     id: '5bf3-5b29-e3c8-4107',
//     status: 200,
//     name: 'ant for rul',
//     typeGame: 'roulette',
//     data: { name: 'desktop', streamId: '285904105870509642215756' },
//     grid: null,
//     createdAt: '2020-01-22T13:24:07.691Z',
//     updatedAt: '2020-01-22T13:24:07.691Z',
//   },
//   lastBalls: [29, 25, 9, 11, 9, 29, 8, 23, 23, 8],
// }

export const gameById = async (id: string): Promise<any> => {
  const response = await http.get(`api/game/${id}`)
  return response.data
}
